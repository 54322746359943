<template>
  <div class="landing-page">
    <nav>
      <div class="container nav-mdxl">
        <router-link to="/guest/welcome" class="logo-mdxl">
          <img src="@/assets/images/landing-page/logo_mdxl.png" alt="logo mdxl" />
        </router-link>
        <!-- <div @click="openModalLogin()" class="btn btn-sm btn-login">
          <i class="fa fa-user pull-left" title="Note" aria-hidden="true"></i> Login
        </div> -->
      </div>
    </nav>
    <div class="container">
      <div class="content">
        <div class="content__information">
          <div class="card_login">
            <a-form
              ref="formRef"
              :model="loginForm"
              :rules="rules"
              layout="vertical"
              class="form-login"
              @finishFailed="handleFinishFailed"
              @submit.prevent="handleFinish"
            >
              <div class="title">
                <h1 for="">
                  Login ke MDexcellent
                </h1>
              </div>
              <a-form-item
                name="email"
                required
                class="email pb-0">
                <a-input
                  v-model:value="loginForm.email"
                  placeholder="Email / Username">
                  <template #prefix>
                    <img src="@/assets/images/icon/ic_store.svg" alt="logo mdxl" />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item
                name="password"
                required
                class="password pb-0">
                <a-input-password
                  v-model:value="loginForm.password"
                  placeholder="Password"
                  type="password">
                  <template #prefix>
                    <img src="@/assets/images/icon/ic_lock.svg" alt="logo mdxl" />
                  </template>
                </a-input-password>
              </a-form-item>
              <a-row type="flex">
                <a-col flex="auto">
                  <div class="remember-me">
                    <input type="checkbox" value=" IngatSaya" />
                    <label for="">Ingat Saya</label>
                  </div>
                </a-col>
                <a-col flex="auto">
                  <div class="forgot-password" @click="goToForgetPassword()">
                    <p class="text-primary password m-0">
                      Lupa Password
                    </p>
                  </div>
                </a-col>
              </a-row>
              
              <div class="login-button my-3">
                <AButton
                  :loading="loading"
                  html-type="submit"
                  size="large"
                  class="tombol w-100 btn-submit">
                  <strong>Sign in</strong>
                </AButton>
              </div>
              <p
                class="text-danger ml-2"
                v-if="showError"
                style="margin-top: 10px; text-align: left;"
              >
                Gagal login, pastikan username dan password benar!
              </p>
              <p
                class="text-danger ml-2"
                v-if="state.authError"
                style="margin-top: 10px; text-align: left;">
                {{ state.authMessage }}
              </p>
              

              <strong>Login dengan: </strong>

              <ASpace>
                <AButton
                  @click="btnWithLogin('microsoft')">
                  <template #icon>
                    <i class="fa fa-windows" />
                  </template>
                  <span class="ml-1">Microsoft</span>
                </AButton>
                <AButton
                  @click="btnWithLogin('google')">
                  <template #icon>
                    <i class="fa fa-google" />
                  </template>
                  <span class="ml-1">Google</span>
                </AButton>
              </ASpace>
            </a-form>
          </div>
          <!-- <label for="">CEPAT. MUDAH. LEBIH MENGUNTUNGKAN</label>
          <p>
            Selamat datang di Solusi Digital Semen Indonesia. Segera daftar dengan ID Toko Anda!
          </p>
          <div @click="openModalLogin()" class="btn btn-sm btn-login">
            Login Akun
          </div> -->
        </div>
        <div class="content__images"></div>
      </div>
    </div>

    <modal-login />
  </div>
</template>

<script>
import ModalLogin from './ModalLogin'
import { useStore } from 'vuex'
import { computed, ref, reactive, onMounted } from 'vue'
import {  useRouter, useRoute } from 'vue-router'
import { Base64Encode } from '@/helpers/utils'

export default {
  name: 'VbWelcome',
  components: {},
  setup() {
    const store = useStore()
    const openModalLogin = () => {
      store.getters.settings.isModalLogin = true
    }

    const formRef = ref();
    const loading = computed(() => store.getters['user/user'].loading)
    const router = useRouter()
    const route = useRoute()
    const showError = ref(false)

    const loginForm = reactive({
      email: '',
      password: '',
    })

    const rules = {
      email: [
        {
          required: true,
          message: 'Please input your email!',
          trigger: 'change',
        },
      ],
      password: [
        {
          required: true,
          message: 'Please input password!',
          trigger: 'change',
        },
      ],
    }

    const state = reactive({
      authError: false,
      authMessage: 'Anda belum terdaftar sebagai pengguna MDXL!',
      auth: {
        microsoft: '/api/auth/ms365',
        google: '/api/auth/google',
      },
    })

    const changeAuthProvider = value => {
      store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
    }
    const handleFinish = async () => {
      // validation form
      await formRef.value.validate()
        .catch(() => { });

      store
        .dispatch('user/LOGIN', { payload: {
          email: loginForm.email,
          password: Base64Encode(loginForm.password),
        } })
        .then(() => {
          showError.value = false
        })
        .catch(error => {
          console.log('Uh oh error : ', error)
          showError.value = true
        })
    }
    const handleFinishFailed = errors => {
      console.log('handleFinishFailed', errors)
      showError.value = false
    }
    const goToForgetPassword = () => {
      router.push('/auth/forgot-password')
      store.getters.settings.isModalLogin = false
    }

    const btnWithLogin = (provider) => {
      window.location.href = process.env.VUE_APP_API_BASE_URL + state.auth[provider]
    }

    onMounted(() => {
      if (route.query.code) {
        store.dispatch('user/AUTH_PROVIDER', { payload: route.query })
          .then(() => {
            state.authError = false
          })
          .catch(error => {
            state.authError = true
            if (error.response) {
              state.authMessage = JSON.parse(error.response.data.text()).message
            }
          })
      }
    })

    return {
      store,
      router,
      ModalLogin,
      loginForm,
      rules,
      showError,
      loading,
      openModalLogin,
      changeAuthProvider,
      handleFinish,
      handleFinishFailed,
      goToForgetPassword,
      btnWithLogin,
      state,
      formRef,
    }
  },
}
</script>

<style lang="scss" scoped>
@import './style/scss/variables.scss';
@import './style/scss/index.scss';
</style>
